import { render, staticRenderFns } from "./CoreBlockContactHero.vue?vue&type=template&id=79620108"
import script from "./CoreBlockContactHero.vue?vue&type=script&lang=js"
export * from "./CoreBlockContactHero.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockContactHero.vue?vue&type=style&index=0&id=79620108&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockImageCropped: require('/usr/src/app/Core.Library/src/components/block/CoreBlockImageCropped.vue').default,CoreBlockCarousel: require('/usr/src/app/Core.Library/src/components/block/CoreBlockCarousel.vue').default})
