
export default {
	name: "CoreBlockCarousel",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		childBlockComponent() {
			return "CoreBlock";
		},
	},
	methods: {
		getSettings() {
			const carouselBlock = {
				type: "rows",
				blocks: [],
				variants: {
					"bg-color": "transparent",
				},
			};

			if (this.settings.arrayMediaSrc) {
				const carouselContainer = {
					...this.settings,
					id: `carousel_${this.settings.id}`,
					type: "container",
					variants: {
						layout: "carousel-hero",
						"child-width": "slim",
					},
					blocks: [],
				};
				for (const element of this.settings.arrayMediaSrc) {
					carouselContainer.blocks.push({
						...element,
						variants: {
							sizing: "contain",
							"bg-color": "transparent",
						},
						width: "100%",
						height: "auto",
					});
				}
				carouselBlock.blocks = [carouselContainer];
			}
		},
	},
};
