
import mediaHelper from "@libraryHelpers/media.js";
import { useLoadComponent } from "@libraryComposables/useLoadComponent";

export default {
	name: "CoreBlockContactHero",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const { componentModule: contactCardModule } = useLoadComponent({
			...props,
			settings: { type: "contact-card" },
		});
		return {
			contactCardModule,
		};
	},
	data() {
		return {
			croppedImage: null,
		};
	},
	computed: {
		background() {
			if (this.mediaIsImg) {
				return {
					"background-image": `url('${this.croppedSrc}')`,
					"background-position": this.mediaBackgroundPosition,
					"background-repeat": "no-repeat",
					"background-size": "cover",
				};
			}
			return null;
		},
		videoMediaSrc() {
			let ret = this.mediaSrc;
			if (mediaHelper.isYoutube(this.mediaSrc)) {
				let embedId = this.mediaSrc.match(
					/https:\/\/www.youtube.com\/embed\/([^\/?]+)/
				);
				if (embedId?.length > 1) {
					ret = `https://www.youtube.com/embed/${embedId}?playlist=${embedId[1]}&controls=0&autoplay=1&fs=0&iv_load_policy=3&loop=1&disablekb=1&modestbranding=1&mute=1`;
				}
			}
			return ret;
		},
		mediaIsVideo() {
			return !!this.mediaSrc && mediaHelper.isVideo(this.mediaSrc);
		},
		mediaIsImg() {
			return !!this.mediaSrc && mediaHelper.isImage(this.mediaSrc);
		},
		mediaSrc() {
			const mediaSrc = this.settings?.backgroundMediaSrc;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		croppedSrc() {
			return this.backgroundMediaCrop?.image
				? this.croppedImage
				: mediaHelper.getEncodedURI(this.mediaSrc);
		},
		mediaBackgroundPosition() {
			return this.backgroundMediaFocal?.bgPosition || "center";
		},
		mediaAlt() {
			const mediaSrc = this.settings?.backgroundMediaSrc;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.alt
				: this.settings?.alt;
		},
		//if backgroundMediaSrc is string means that the image has the above format, now backgroundMediaSrc is a object
		//when you add o edit the image, It will be saved as object
		backgroundMedia() {
			const mediaSrc = this.settings?.backgroundMediaSrc;
			const { crop, focal } = mediaSrc;
			if (crop || focal) {
				const mediaObject = crop || {};
				if (focal) mediaObject.focal = focal;
				return mediaObject;
			}
			return this.settings?.mediaMetadata?.backgroundMediaSrc;
		},
		backgroundMediaCrop() {
			return this.backgroundMedia;
		},
		backgroundMediaFocal() {
			return this.backgroundMedia?.focal;
		},
		settingsObj() {
			return this.settings;
		},
		dataSiteObj() {
			return this.dataSite;
		},
	},
	methods: {
		getCarouselSettings() {
			const imageBlock = {
				type: "rows",
				blocks: [],
				variants: {
					"bg-color": "transparent",
				},
			};
			if (this.settings.arrayMediaSrc) {
				const carouselContainer = {
					...this.settings,
					id: `carousel_${this.settings.id}`,
					type: "container",
					variants: {
						layout: "carousel-hero",
						"child-width": "slim",
					},
					blocks: [],
				};
				for (const element of this.settings.arrayMediaSrc) {
					carouselContainer.blocks.push({
						...element,
						variants: {
							sizing: "contain",
							"bg-color": "transparent",
						},
						width: "100%",
						height: "auto",
					});
				}

				imageBlock.blocks = [carouselContainer];
			}
			return imageBlock;
		},
	},
};
